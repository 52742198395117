import { Injectable } from '@angular/core';
import { map, tap, switchMap } from 'rxjs/operators';
import { BehaviorSubject, from, Observable, Subject } from 'rxjs';
import { ToastController, LoadingController } from '@ionic/angular';
import { TOKEN_KEY, ORDER_STATUS, BASEURL, USER_NAME, USER_EMAIL, USER_MOBILE, USER_PROFILE } from '../constant/constants';
import { Plugins } from '@capacitor/core';
const { Storage } = Plugins;
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class AuthenticationService {
  loading: any;
  alert: any;
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);
  token = '';
  orderStatus: any;
  authInfo: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(null);

  constructor(
    private http: HttpClient,
    private loadingController: LoadingController,
    private toastController: ToastController
  ) {
    this.loadToken();
    this.getOrderStatus();
  }

  async callUpdate() {
    this.authInfo.next(true);
    return null;
  }

  async getToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    return token;
  }

  sendToken() {
    this.getToken().then(result => {
      var token = result.value;
      return token;
    });
  }

  getheaders() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Accept': 'application/json',
        'x-auth-token': this.token
      })
    };
    return httpOptions;
  }

  getFinalOrderStatus() {
    return this.orderStatus;
  }

  async loadToken() {
    const token = await Storage.get({ key: TOKEN_KEY });
    if (token && token.value) {
      this.token = token.value;
      this.isAuthenticated.next(true);
    } else {
      this.isAuthenticated.next(false);
    }
  }

  async getOrderStatus() {
    const token = await Storage.get({ key: ORDER_STATUS });
    if (token && token.value) {
      this.orderStatus = token.value;
      return this.orderStatus;
    }
  }

  /* Reviewed */
  async startLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-loading-class',
      message: 'Please wait...',
    });
    await this.loading.present();
  }

  /* Reviewed */
  dismissLoading() {
    this.loading.dismiss();
  }

  /* Reviewed */
  getTailorList(credentials): Observable<any> {
    return this.http.get(BASEURL + "/tailor-designer/ionic/login-get-list/" + credentials);
  }

  /* Reviewed */
  loginAsTailor(credentials: { token, id, device_type: 1, device_fcm: "fsjdnfhjdfhj" }): Observable<any> {
    return this.http.post(`${BASEURL}/tailor-designer/ionic/login-as-tailor`, credentials).pipe(
      tap(async (res: any) => {
        if (res.success) {
          this.isAuthenticated.next(true);
          this.token = res.token;
          Storage.set({ key: ORDER_STATUS, value: res.tailorsDesigner.order_status });
          Storage.set({ key: TOKEN_KEY, value: this.token });
        }
      })
    )
  }

  /* Reviewed */
  login(credentials: { phone_number, password, device_type: 1, device_fcm: "fsjdnfhjdfhj" }): Observable<any> {
    return this.http.post(`${BASEURL}/tailor-designer/ionic/login`, credentials).pipe(
      tap(async (res: any) => {
        if (res.success) {
          this.isAuthenticated.next(true);
          this.token = res.token;
          Storage.set({ key: ORDER_STATUS, value: res.tailorsDesigner.order_status });
          Storage.set({ key: TOKEN_KEY, value: this.token });
        }
      })
    )
  }

  /* Reviewed */
  logout(): Promise<void> {
    this.isAuthenticated.next(false);
    this.logoutApi();
    return this.deleteStorage();
  }

  /* Reviewed */
  deleteStorage(): Promise<void> {
    Storage.remove({ key: USER_EMAIL });
    Storage.remove({ key: USER_MOBILE });
    Storage.remove({ key: USER_NAME });
    Storage.remove({ key: USER_PROFILE });
    Storage.remove({ key: ORDER_STATUS });
    return Storage.remove({ key: TOKEN_KEY });
  }

  /* Reviewed */
  async logoutApi() {
    const data = {
      token: this.token
    }
    await this.startLoading();
    this.http.put(BASEURL + "/tailor-designer/ionic/logout", data, (this.getheaders())).subscribe(async (res: any) => {
      await this.dismissLoading();
      if (!res.success) {
        await this.showToast(res.message, 2);
      }
    },
      async (res) => {
        await this.dismissLoading();
        await this.showToast(res.error.message, 2);
      }
    );
  }

  /* Reviewed */
  async showToast(message: string, type: number) {
    switch (type) {
      case 0:
        this.alert = await this.toastController.create({
          position: 'bottom',
          duration: 2000,
          animated: true,
          cssClass: 'toast-class',
          color: 'warning',
          buttons: [
            {
              side: 'start',
              icon: 'alert-outline',
              text: message,
            }
          ]
        });
        await this.alert.present();
        break;
      case 1:
        this.alert = await this.toastController.create({
          position: 'bottom',
          duration: 2000,
          animated: true,
          cssClass: 'toast-class',
          color: 'success',
          buttons: [
            {
              side: 'start',
              icon: 'checkmark-done-outline',
              text: message,
            }
          ]
        });
        await this.alert.present();
        break;
      case 2:
        this.alert = await this.toastController.create({
          position: 'bottom',
          duration: 2000,
          animated: true,
          cssClass: 'toast-class',
          color: 'danger',
          buttons: [
            {
              side: 'start',
              icon: 'alert-outline',
              text: message,
            }
          ]
        });
        await this.alert.present();
        break;
      default:
        break;
    }
  }
}