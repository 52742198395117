export const gc_refresh_time_ms = 1000;
export const appVersion = "V 2.1";
export const DEVICE_TYPE = 3;           //to be changed as per install device 1 - android, 2 - ios, 3 - Web

// export const BASEURL = "http://127.0.0.1:3001/api";
export const BASEURL = "https://be.dev.mazata.in/api";
// export const BASEURL = "https://be.qty.mazata.in/api";
// export const BASEURL = "https://be.app.mazata.in/api";

export const defaultProfileUrl = "https://ui-avatars.com/api/?background=F98E1D&color=FFFFFF&name=M";

// Profile Api
export const PROFILE_URL = "/tailor-designer/ionic";
export const UPDATE_PROFILE = "/profile-update";

// Designer User Type
export const DESIGNER_USER_TYPE = '3';

// Tailoring service Price
export const TAILORING_SERVICE_PRICE_URL = "/tailoring-service/price";

// upload your own design
export const UPLOAD_OWN_DESIGN_URL = "/uod-tailor-bidding";
export const BIDDING_URL = "/get-bidding";

export const TOKEN_KEY = 'x-auth-token';
export const ORDER_STATUS = 'order_status';
export const ORDER_STATUS__ON = '1';
export const ORDER_STATUS__OFF = '2';
export const USER_NAME = 'user_name';
export const USER_EMAIL = 'user_email';
export const USER_MOBILE = 'user_mobile';
export const USER_PROFILE = 'user_profile';
export const FCM_TOKEN = 'fcm_token';
export const OLD_FCM_TOKEN = 'old_fcm_token';

export const order_status_res = "Availability status changed";
export const no_products = "No products created, add new products to start selling";
export const under_development = "Under Development";

// variant insert page
export const var_ins = "Minimum one image is needed to create a product";
export const var_size = "Select one size for adding Accessories variant";
export const ins_product = "From Age should be less than To Age";
export const discount_price_check = "Discount Price should be less than (or) equal to Actual Price";

export const calling_TD = "Calling ";

export const gc_invoice_show = 1;
export const gc_invoice_hide = 0;

//Navigation Link IDS
export const gc_exit_app = 0;
export const gc_nli_home = 1;
export const gc_nli_profile = 2;
export const gc_nli_store_loc = 3;
export const gc_nli_chg_pass = 4;
export const gc_nli_login = 5;
export const gc_nli_logout = 6;
export const gc_nli_ts_list = 7;
export const gc_nli_td_order_list = 8;
export const gc_nli_td_order_list_completed = 9;
export const gc_nli_ts_order_list = 10;
export const gc_nli_ts_order_list_completed = 11;
export const gc_nli_product_list = 12;
export const gc_nli_product_list_rm = 13;
export const gc_nli_product_list_bt = 14;
export const gc_nli_terms = 15;
export const gc_nli_privacy = 16;
export const gc_nli_rm_order_list = 17;
export const gc_nli_rm_order_list_completed = 18;
export const gc_nli_bt_order_list = 19;
export const gc_nli_bt_order_list_completed = 20;
export const gc_nli_bt_bidding_list = 21;
export const gc_nli_bt_uod_order_list = 22;
export const gc_nli_bt_uod_order_list_completed = 23;
export const gc_nli_bt_transaction_page = 24;
export const gc_nli_bt_transaction_graph = 25;
export const gc_nli_profile_edit = 26;
export const gc_nli_goback = 999;
export const gc_nli_default = 1000;

export const gc_txt_var_all_services = 0;
export const gc_txt_var_trending_design = 1;
export const gc_txt_var_upload_design = 2;
export const gc_txt_var_boutiques = 3;
export const gc_txt_var_tailor_services = 4;
export const gc_txt_var_readymades = 5;

//Button Type
export const gc_bt_back = 0;
export const gc_bt_menu = 1;

// Tax Margin
export const gc_tm_less_than_or_equal = 1;
export const gc_tm_great_than = 2;

//Push Notification DataTypes
export const gc_pndt_campaign = 1;
export const gc_pndt_order_status = 2;
export const gc_pndt_low_stock = 3;
export const gc_pndt_assignments = 4;

//Services Constants
export const ALLSERVICES = 0;
export const TRENDINGDESIGN = 1;
export const OWNUPLOAD = 2;
export const BOUTIQUE = 3;
export const TAILORINGSERVICE = 4;
export const READYMADE = 5;

//Services Description 
export const gc_txt_all_servicess = "All Services";
export const gc_txt_trending_design = "Made 2 Measure";
export const gc_txt_upload_design = "Upload Own Design";
export const gc_txt_boutiques = "Boutiques";
export const gc_txt_tailor_services = "Tailoring Services";
export const gc_txt_readymades = "Accessories";

//TR Orderstatus New One
export const gc_tdn_os_measurement_unassign = 0;
export const gc_tdn_os_measurement_assign = 1;
export const gc_tdn_os_measurement_completed = 2; // un accepted order TD 

export const gc_tdn_os_order_accepted_TD = 3; // accepted order TD
export const gc_tdn_os_ready_for_delivery = 4; // unassigned collection
export const gc_tdn_os_assigned_collection = 5; // assigned collection

// gone from list TD if same zone -> deliver, if diff -> hub , assign delivery TA ID
export const gc_tdn_os_measurement_order_collected = 6; // move to delivery tab, diff goto hub tab, same goto delivery tab

//deliver to customer
export const gc_tdn_os_measurement_order_home = 7; // show return btn in customer app

// products currently in hub
export const gc_tdn_os_measurement_order_in_hub = 8;

// to the destination zone move to unassigned delivery
export const gc_tdn_os_measurement_order_submittted_in_hub = 9;

//App ID
export const gc_app_id_business = 2;

//Document types
export const gc_bdt_return_policy = 1;
export const gc_bdt_privacy_policy = 2;
export const gc_bdt_terms_policy = 3;

export const DEFAULT_URL = "https://ui-avatars.com/api/?background=F98E1D&color=FFFFFF&name=M";

export const image_count_issue = "Maximum Only 5 Images allowed";
export const less_image_count = "Upload Atleast One Image";
export const noQuery = "No custom design request posted";
export const max_image_count = 5;


// screen title
export const gc_scrn_title_bidding_list = "Own Design Request";
export const gc_scrn_title_bidding = "Design Request Details";
export const gc_scrn_title_bidding_add = "Add Proposal";
export const gc_scrn_title_bidding_edit = "Edit Proposal";
export const gc_scrn_title_review = "Review";

// transaction cash flow type
export const gc_tcf_paid = 1;
export const gc_tcf_received = 2;

export const gc_tcf_paid_txt = "PAID";
export const gc_tcf_received_txt = "RECEIVED";
export const gc_tcf_taken_txt = "TAKEN";
export const gc_tcf_claimed_txt = "CLAIMED";

// transaction cost center
export const gc_cc_advance = 1;
export const gc_cc_penality = 2;
export const gc_cc_refund = 3;

export const gc_cc_penality_txt = "PENALTY";
export const gc_cc_advance_txt = "ADVANCE AMOUNT";
export const gc_cc_refund_txt = "REFUND";

//pan details
export const gc_designer = 1;
export const gc_Mazata = 2;

export const gc_cf_inwards = 1; //inward received by mazata from designer
export const gc_cf_outwards = 2; //paid by mazata to designer

//Image Reference types
export const gc_ir_variant_image = 1;  //Products
export const gc_ir_campaign_image = 2; //Measurement image
export const gc_ir_UOD_order = 3;      //Upload Own Design
export const gc_ir_variant_image_tailoringService = 4; // tailoring service image

//Activation Status   1 - Active, 2 - Deactivate
export const gc_default = 0;
export const gc_active = 1;
export const gc_inActive = 2;

//gender Asssignment
export const gc_gender_male = 1;
export const gc_gender_female = 2;

// Product DesignTypes
export const gm_dt_modern = 1;
export const gm_dt_ethinic = 2;

//GPS Options
export const gc_gps_options = {
    timeout: 20000,
    enableHighAccuracy: false,
};

//Default co-ordinates
export const gc_dc_lat = 13.067439;
export const gc_dc_long = 80.237617;