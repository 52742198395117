import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  // {
  //   path: 'home',
  //   loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'splash',
    loadChildren: () => import('./splash/splash.module').then(m => m.SplashPageModule)
  },
  // {
  //   path: 'profile',
  //   loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then(m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'forget-password',
    loadChildren: () => import('./forget-password/forget-password.module').then(m => m.ForgetPasswordPageModule),
    canLoad: [AutoLoginGuard]
  },
  // {
  //   path: 'intro',
  //   loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule)
  // },
  // {
  //   path: 'location',
  //   loadChildren: () => import('./location/location.module').then(m => m.LocationPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'home-sheet',
    loadChildren: () => import('./home-sheet/home-sheet.module').then(m => m.HomeSheetPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'product-list/:service_id',  // service id
    loadChildren: () => import('./product-list/product-list.module').then(m => m.ProductListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'product-add/:ref_id/:serviceId',
    loadChildren: () => import('./product-add/product-add.module').then(m => m.ProductAddPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'no-internet',
    loadChildren: () => import('./no-internet/no-internet.module').then(m => m.NoInternetPageModule)
  },
  {
    path: 'order/:com_status/:service_id',
    loadChildren: () => import('./order/order.module').then(m => m.OrderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'order-detail/:id/:serviceId',
    loadChildren: () => import('./order-detail/order-detail.module').then(m => m.OrderDetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variant-insert/:productId/:productRefId/:serviceId/:subsubcategoryId',
    loadChildren: () => import('./variant-insert/variant-insert.module').then(m => m.VariantInsertPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variant-insert-size/:productId/:productRefId/:serviceId/:subsubcategoryId/:color_id',
    loadChildren: () => import('./variant-insert-size/variant-insert-size.module').then(m => m.VariantInsertSizePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'product-edit/:ref_id/:service_id',
    loadChildren: () => import('./product-edit/product-edit.module').then(m => m.ProductEditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variant-edit/:productId/:variantId/:productRefId/:varRefId/:service_id',
    loadChildren: () => import('./variant-edit/variant-edit.module').then(m => m.VariantEditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'tailoringprice',
    loadChildren: () => import('./tailoringprice/tailoringprice.module').then(m => m.TailoringpricePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'addtailoringprice',
    loadChildren: () => import('./addtailoringprice/addtailoringprice.module').then(m => m.AddtailoringpricePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edittailoringprice/:id',
    loadChildren: () => import('./edittailoringprice/edittailoringprice.module').then(m => m.EdittailoringpricePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'measurement-quantity-list',
    loadChildren: () => import('./measurement/measurement-quantity-list/measurement-quantity-list.module').then(m => m.MeasurementQuantityListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'measurement-data/:headerID/:ProductId/:qty/:subsubcategoryId',
    loadChildren: () => import('./measurement/measurement-data/measurement-data.module').then(m => m.MeasurementDataPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variant-show/:productId/:productRefId/:service_id',
    loadChildren: () => import('./variant-show/variant-show.module').then(m => m.VariantShowPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'variant-show-size/:productId/:productRefId/:service_id/:color_id',
    loadChildren: () => import('./variant-show-size/variant-show-size.module').then(m => m.VariantShowSizePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'location',
    loadChildren: () => import('./location/location.module').then(m => m.LocationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'ts-order/:com_status',
    loadChildren: () => import('./ts-order/ts-order.module').then(m => m.TsOrderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'view-style-attribute/:orderId/:productId/:serviceid/:tdId',
    loadChildren: () => import('./view-style-attribute/view-style-attribute.module').then(m => m.ViewStyleAttributePageModule)
  },
  {
    path: 'replace-policy/:id',
    loadChildren: () => import('./replace-policy/replace-policy.module').then(m => m.ReplacePolicyPageModule)
  },
  {
    path: 'bidding-list',
    loadChildren: () => import('./bidding-list/bidding-list.module').then(m => m.BiddingListPageModule)
  },
  {
    path: 'bidding-add/:id',
    loadChildren: () => import('./bidding-add/bidding-add.module').then(m => m.BiddingAddPageModule)
  },
  {
    path: 'bidding-edit/:id',
    loadChildren: () => import('./bidding-edit/bidding-edit.module').then(m => m.BiddingEditPageModule)
  },
  {
    path: 'bidding-start/:id',
    loadChildren: () => import('./bidding-start/bidding-start.module').then(m => m.BiddingStartPageModule)
  },
  {
    path: 'review-page/:productId/:serviceId',
    loadChildren: () => import('./review-page/review-page.module').then(m => m.ReviewPagePageModule)
  },
  {
    path: 'bidding-show/:id',
    loadChildren: () => import('./bidding-show/bidding-show.module').then(m => m.BiddingShowPageModule)
  },
  {
    path: 'transactions/:order_header_id',
    loadChildren: () => import('./transactions/transactions.module').then(m => m.TransactionsPageModule)
  },
  {
    path: 'image-preview-page',
    loadChildren: () => import('./image-preview-page/image-preview-page.module').then(m => m.ImagePreviewPagePageModule)
  },
  {
    path: 'finance-graph',
    loadChildren: () => import('./finance-graph/finance-graph.module').then(m => m.FinanceGraphPageModule)
  },
  {
    path: 'select-tailor/:token_id',
    loadChildren: () => import('./select-tailor/select-tailor.module').then(m => m.SelectTailorPageModule)
  }

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
