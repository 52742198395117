/* Reviewed */
import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthenticationService } from './services/authentication.service';
import { Platform } from '@ionic/angular';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Plugins, PushNotification, PushNotificationToken, PushNotificationActionPerformed, PluginListenerHandle } from '@capacitor/core';
const { PushNotifications, Storage, App, Network } = Plugins;
import { USER_PROFILE, USER_MOBILE, USER_EMAIL, USER_NAME, FCM_TOKEN, gc_nli_home, gc_nli_bt_transaction_graph, gc_nli_profile, gc_nli_store_loc, gc_nli_chg_pass, gc_nli_logout, gc_pndt_order_status, gc_pndt_low_stock, gc_nli_terms, gc_nli_privacy, gc_bdt_privacy_policy, gc_bdt_terms_policy, defaultProfileUrl, gc_nli_bt_transaction_page, appVersion } from './constant/constants';
import { AlertController, IonRouterOutlet } from '@ionic/angular';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent implements OnInit {
  @ViewChild(IonRouterOutlet, { static: true }) routerOutlet: IonRouterOutlet;
  userName: any;
  userNumber: any;
  userEmail: any;
  userProfile: any;
  networkStatus: any;
  networkListener: PluginListenerHandle;
  defaultProfileUrl = defaultProfileUrl;
  appVersion = appVersion;

  gc_nli_home = gc_nli_home;
  gc_nli_profile = gc_nli_profile;
  gc_nli_store_loc = gc_nli_store_loc;
  gc_nli_chg_pass = gc_nli_chg_pass;
  gc_nli_bt_transaction_page = gc_nli_bt_transaction_page;
  gc_nli_terms = gc_nli_terms;
  gc_nli_privacy = gc_nli_privacy;
  gc_nli_bt_transaction_graph = gc_nli_bt_transaction_graph;
  gc_nli_logout = gc_nli_logout;

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private router: Router,
    private authService: AuthenticationService,
    public alertController: AlertController,
    private location: Location
  ) {
    this.initializeApp();
    this.backButtonEvent();
  }

  backButtonEvent() {
    this.platform.backButton.subscribeWithPriority(10, () => {
      this.backButtonAlert();
    });
  }

  async backButtonAlert() {
    if (!this.routerOutlet.canGoBack()) {
      // check there is no way to go back
      App.exitApp();
    } else {
      // goes back
      this.location.back();
    }
  }

  public appPages = [
    { title: 'Home', url: '/home', icon: '../assets/icon/nav_home.png' },
    { title: 'My Profile', url: '/profile', icon: '../assets/icon/nav_profile.png' },
    { title: 'Location', url: '/location', icon: '../assets/icon/nav_places.png' },
    { title: 'Notifications', url: '', icon: '../assets/icon/nav_noti.png' },
    { title: 'Rate Us', url: '', icon: '../assets/icon/nav_start.png' },
    { title: 'App Info', url: '', icon: '../assets/icon/nav_info.png' },
    { title: 'Support', url: '', icon: '../assets/icon/nav_support.png' },
    { title: 'Logout', url: '', icon: '../assets/icon/nav_logout.png' },
  ];

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.setupPushNotification();
    });
  }

  async gotoPage(index: number) {
    switch (index) {
      case gc_nli_home:
        this.router.navigateByUrl('home');
        break;
      case gc_nli_profile:
        this.router.navigateByUrl('profile');
        break;
      case gc_nli_store_loc:
        this.router.navigateByUrl('location');
        break;
      case gc_nli_chg_pass:
        this.router.navigateByUrl('change-password');
        break;
      case gc_nli_bt_transaction_page:
        this.router.navigateByUrl('transactions/0');
        break;
      case gc_nli_terms:
        this.router.navigateByUrl('replace-policy/' + gc_bdt_terms_policy);
        break;
      case gc_nli_privacy:
        this.router.navigateByUrl('replace-policy/' + gc_bdt_privacy_policy);
        break;
      case gc_nli_bt_transaction_graph:
        this.router.navigateByUrl('finance-graph');
        break;
      case gc_nli_logout:
        await this.authService.logout();
        this.router.navigate(['login'], { replaceUrl: true });
        break;
      default:
        this.router.navigateByUrl('home');
        break;
    }
  }

  ngOnInit() {
    this.networkListener = Network.addListener('networkStatusChange', (status) => {
      this.networkStatus = status;
      if (!this.networkStatus.connected) {
        // this.router.navigateByUrl('no-internet');
      }
    });

    this.authService.authInfo.subscribe(() => {
      this.getProfileData();
    });
    this.getNetWorkStatus();
  }

  async setupPushNotification() {
    var permission = PushNotifications.requestPermission();
    if (permission) {
      PushNotifications.register();
      PushNotifications.addListener('registration',
        (token: PushNotificationToken) => {
          Storage.set({ key: FCM_TOKEN, value: token.value });
        }
      );

      // error handling
      PushNotifications.addListener('registrationError',
        (error: any) => {
          console.log(error);
        }
      );

      // when app is open this pop-up when notification is send
      PushNotifications.addListener('pushNotificationReceived',
        async (notification: PushNotification) => {
          const alert = await this.alertController.create({
            cssClass: 'my-custom-class',
            header: notification.title,
            message: notification.body,
            buttons: [
              {
                text: 'Cancel',
                role: 'cancel',
                cssClass: 'secondary',
                handler: async () => {
                  await alert.dismiss();
                }
              }, {
                text: 'Okay',
                handler: async () => {
                  await alert.dismiss();
                  this.handlePushNotificationClick(notification);
                }
              }
            ]
          });
          await alert.present();
        }
      );

      // runs while notification is clicked
      PushNotifications.addListener('pushNotificationActionPerformed',
        (notification: PushNotificationActionPerformed) => {
          this.handlePushNotificationClick(notification);
        }
      );
    }
  }

  async getNetWorkStatus() {
    this.networkStatus = await Network.getStatus();
    if (!this.networkStatus.connected) {
      // this.router.navigateByUrl('no-internet');
    }
  }

  handlePushNotificationClick(notification) {
    if (notification.notification.data != null) {
      if (notification.notification.data.notificationType != null) {
        try {
          var ntype = parseInt(notification.notification.data.notificationType);
          switch (ntype) {
            case gc_pndt_order_status:
              if (notification.notification.data.orderHeaderId != null && notification.notification.data.orderServiceId != null) {
                this.router.navigateByUrl('order-detail/' + notification.notification.data.orderHeaderId + "/" + notification.notification.data.orderServiceId);
              }
              break;
            case gc_pndt_low_stock:
              if (notification.notification.data.productattrId != null && notification.notification.data.productId != null) {
                this.router.navigateByUrl('variant-edit/' + notification.notification.data.productId + "/" + notification.notification.data.productattrId + "/" + notification.notification.data.ProductRef + "/" + notification.notification.data.VariantRef);
              }
              break;
            default:
              break;
          }
        } catch (error) {

        }
      }
    }
  }

  async getProfileData() {
    const profileImg = await Storage.get({ key: USER_PROFILE });
    if (profileImg && profileImg.value && profileImg.value != "") {
      this.userProfile = profileImg.value;
    } else {
      this.userProfile = this.defaultProfileUrl;
    }

    const userNumber = await Storage.get({ key: USER_MOBILE });
    if (userNumber && userNumber.value) {
      this.userNumber = userNumber.value;
    }

    const userEmail = await Storage.get({ key: USER_EMAIL });
    if (userEmail && userEmail.value) {
      this.userEmail = userEmail.value;
    }

    const username = await Storage.get({ key: USER_NAME });
    if (username && username.value) {
      this.userName = username.value;
    }
  }
}
